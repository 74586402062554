import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Toast, ToastModule } from '@syncfusion/ej2-angular-notifications';
import { SignalRMethodType } from './models/singlalr-method-type';
import { ToastDetails, ToastType } from './models/toast-details';
import { NotificationService } from './services/notification.service';
import { SignalrService } from './services/signalr.service';
import { CacheService, GetAllEngineerSignatures, GetAllClientTasks, GetAllClients, GetDeletedClientTasks, GetEngineersWithChimneySweeps, GetManagers, GetOwners, GetCurrentWorkflowYearDates, GetAllTags, GetAllUsers } from './services/cache.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [ToastModule, RouterOutlet]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Frontend';
  @ViewChild('toast', { static: true }) toast: Toast;
  public position = { X: 'Center', Y: 'Top' };
  public templateEle = `<div id='template_toast' style="display: none"><div class="horizontal-align"><div class='toast-content'><div class='toast-title'>{{title}}</div><div class='toast-message'>{{content}}</div></div></div></div>`;
  public template: string;
  public newestToast: ToastDetails;

  constructor(notificationService: NotificationService, private signalrService: SignalrService, private cacheService: CacheService) {
    notificationService.toastObservable.subscribe(toast => {
      if (toast && this.toast)
        this.showToast(toast);
    })
  }

  ngOnDestroy(): void {
    this.signalrService.hubConnection.off(SignalRMethodType[SignalRMethodType.CacheNotification]);
  }

  ngOnInit(): void {
    this.signalrService.startConnection("cache");
    this.signalrService.hubConnection.on(SignalRMethodType[SignalRMethodType.CacheNotification], (commandName: string) => {
      console.log(`[SignalR] received after successfully finished: ${commandName}`);

      if (commandName == "CreateClientCommand" || commandName == "DeleteClientCommand" || commandName == "EditClientCommand") {
        this.cacheService.getData(GetAllClients, true);
      }

      if (commandName == "CreateClientCommand" || commandName == "DeleteClientCommand" || commandName == "EditClientCommand" ||
        commandName == "CreateClientTaskCommand" || commandName == "EditClientTaskCommand" || commandName == "AssignManagersCommand" ||
        commandName == "UnassignManagersCommand" || commandName == "ChangeStatusOfIsDeletedCommand") {
        this.cacheService.getData(GetAllClientTasks, true);
      }

      if (commandName == "ChangeStatusOfIsDeletedCommand") {
        this.cacheService.getData(GetDeletedClientTasks, true);
      }

      if (commandName == "CreateUserCommand" || commandName == "EditUserCommand" || commandName == "EditProfileDetailsCommand") {
        this.cacheService.getData(GetEngineersWithChimneySweeps, true);
        this.cacheService.getData(GetManagers, true);
        this.cacheService.getData(GetOwners, true);
      }

      if (commandName == "RemoveSignatureCommand" || commandName == "UploadSignatureCommand") {
        this.cacheService.getData(GetAllEngineerSignatures, true);
      }

      if (commandName == "WorkflowDayOrMonthForWholeYearPeriod") {
        this.cacheService.getData(GetCurrentWorkflowYearDates, true);
      }

      if (commandName == "AddTagsCommand" || commandName == "DeleteTagsCommand") {
        this.cacheService.getData(GetAllTags, true);
      }

      if (commandName == "EditProfileDetailsCommand" || commandName == "ActivateUserCommand" || commandName == "CreateUserCommand" 
        || commandName == "DeactivateUserCommand" || commandName == "EditUserCommand") {
        this.cacheService.getData(GetAllUsers, true);
      }
    });
  }

  showToast(toastDetails: ToastDetails) {
    if (toastDetails) {
      this.template = `${this.templateEle}`;
      this.template = this.template.replace('{{title}}', toastDetails.title);
      this.template = this.template.replace('{{content}}', toastDetails.content);
      this.toast.template = this.template;
      switch (toastDetails.type) {
        case <number>ToastType.success:
          this.toast.cssClass = 'e-toast-success';
          break;
        case <number>ToastType.info:
          this.toast.cssClass = 'e-toast-info';
          break;
        case <number>ToastType.warning:
          this.toast.cssClass = 'e-toast-warning';
          break;
        case <number>ToastType.error:
          this.toast.cssClass = 'e-toast-danger';
          break;
        default:
          break;
      }

      this.toast.show();
    }
  }
}
