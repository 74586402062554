import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ADD, COMMENT, EDIT, gazowy, gazowyPolroczny, kominiarski, kominiarskiPolroczny, kontrolaDokumentacji, piecioletni, polroczny, roczny, SHOW } from './components/protocols/protocol-functions.service';
import { ErrorCodePageComponent } from './components/shared/error-code-page/error-code-page.component';
import { ClientType } from './models/client-type';
import { AuthGuardService } from './services/auth-guard.service';
import { RoleGuard } from './services/role.guard';

const zwykly: string = ClientType[ClientType.Zwykly];
const msk: string = ClientType[ClientType.Muszkieter];
const kamienice: string = ClientType[ClientType.LodzkieKamienice];

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./components/layout/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: 'aktywujKonto',
    loadComponent: () => import('./components/account/activate-account/activate-account.component').then(m => m.ActivateAccountComponent)
  },
  {
    path: 'zmienHaslo',
    loadComponent: () => import('./components/account/change-password/change-password.component').then(m => m.ChangePasswordComponent)
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./components/layout/main/main.component').then(m => m.MainComponent),
    children: [
      {
        path: '',
        canActivate: [AuthGuardService],
        loadComponent: () => import('./components/layout/home/home.component').then(m => m.HomeComponent),
      },
      // TODO @defer są super bo rozbijam ładowane fragmenty na chunki czyli zamiast jednego pierdolca wielkiego można rozbic komponent na jeszcze chunki iii to po @if
      // @defer (on interaction(loadSummaries); prefetch on idle) ZDJĘCIA ROZPYKANE --> np łądowane są tylko jak się rozwinie te elementy a takto w chuju
      {
        path: 'klienci',
        canActivate: [RoleGuard],
        data: { roleClaims: ['admin_access', 'manager_access'] },
        children: [
          {
            path: '',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access', 'manager_access'] },
            loadComponent: () => import('./components/clients/clients-dashboard/clients-dashboard.component').then(m => m.ClientsDashboardComponent)
          },
          {
            path: 'dodaj',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access', 'manager_access'] },
            loadComponent: () => import('./components/clients/client-add/client-add.component').then(m => m.ClientAddComponent)
          },
          {
            path: ':id',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access', 'manager_access'] },
            loadComponent: () => import('./components/clients/client-details/client-details.component').then(m => m.ClientDetailsComponent)
          },
          {
            path: 'edytuj/:id',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access', 'manager_access'] },
            loadComponent: () => import('./components/clients/client-edit/client-edit.component').then(m => m.ClientEditComponent)
          }
        ]
      },
      {
        path: 'zadania',
        canActivate: [RoleGuard],
        data: { roleClaims: ['engineer_access'] },
        children: [
          {
            path: '',
            canActivate: [RoleGuard],
            data: { roleClaims: ['engineer_access'] },
            loadComponent: () => import('./components/client-tasks/client-tasks/client-tasks.component').then(m => m.ClientTasksComponent)
          },
          {
            path: 'dodaj/:id',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access', 'manager_access'] },
            loadComponent: () => import('./components/client-tasks/client-task-add/client-task-add.component').then(m => m.ClientTaskAddComponent)
          },
          {
            path: ':client/:task',
            canActivate: [RoleGuard],
            data: { roleClaims: ['engineer_access'] },
            loadComponent: () => import('./components/client-tasks/client-task-details/client-task-details.component').then(m => m.ClientTaskDetailsComponent)
          },
          {
            path: 'edytuj/:client/:task',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access', 'manager_access'] },
            loadComponent: () => import('./components/client-tasks/client-task-edit/client-task-edit.component').then(m => m.ClientTaskEditComponent)
          },
        ]
      },
      {
        path: 'obiekty',
        canActivate: [RoleGuard],
        data: { roleClaims: ['engineer_access'] },
        children: [
          {
            path: ':id',
            canActivate: [RoleGuard],
            data: { roleClaims: ['engineer_access'] },
            loadComponent: () => import('./components/work-objects/work-object-details/work-object-details.component').then(m => m.WorkObjectDetailsComponent)
          },
          {
            path: 'zdjecia/:id',
            canActivate: [RoleGuard],
            data: { roleClaims: ['engineer_access'] },
            loadComponent: () => import('./components/work-objects/work-object-photos/work-object-photos.component').then(m => m.WorkObjectPhotosComponent)
          }
        ]
      },
      {
        path: 'admin',
        canActivate: [RoleGuard],
        data: { roleClaims: ['admin_access'] },
        children: [
          {
            path: 'ustawienia',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access'] },
            loadComponent: () => import('./components/admin/settings/settings.component').then(m => m.SettingsComponent)
          },
          {
            path: 'raporty',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access'] },
            loadComponent: () => import('./components/admin/manage-reports/manage-reports.component').then(m => m.ManageReportsComponent)
          },
          {
            path: 'uzytkownicy',
            canActivate: [RoleGuard],
            data: { roleClaims: ['admin_access'] },
            children: [
              {
                path: '',
                canActivate: [RoleGuard],
                data: { roleClaims: ['admin_access'] },
                loadComponent: () => import('./components/admin/users/users.component').then(m => m.UsersComponent)
              },
              {
                path: 'dodaj',
                canActivate: [RoleGuard],
                data: { roleClaims: ['admin_access'] },
                loadComponent: () => import('./components/admin/user-add/user-add.component').then(m => m.UserAddComponent)
              },
              {
                path: 'edytuj/:id',
                canActivate: [RoleGuard],
                data: { roleClaims: ['admin_access'] },
                loadComponent: () => import('./components/admin/user-edit/user-edit.component').then(m => m.UserEditComponent)
              }
            ]
          }
        ]
      },
      {
        path: 'protokoly',
        canActivate: [RoleGuard],
        data: { roleClaims: ['engineer_access'] },
        children:
          [
            {
              path: `${zwykly}`,
              canActivate: [RoleGuard],
              data: { roleClaims: ['engineer_access'] },
              children: [
                {
                  path: `${roczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/zwykly/annual/annual.component').then(m => m.AnnualComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/zwykly/annual/annual.component').then(m => m.AnnualComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/zwykly/annual/annual.component').then(m => m.AnnualComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/zwykly/annual/annual.component').then(m => m.AnnualComponent) }
                  ]
                },
                {
                  path: `${piecioletni}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/zwykly/quinquennial/quinquennial.component').then(m => m.QuinquennialComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/zwykly/quinquennial/quinquennial.component').then(m => m.QuinquennialComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/zwykly/quinquennial/quinquennial.component').then(m => m.QuinquennialComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/zwykly/quinquennial/quinquennial.component').then(m => m.QuinquennialComponent) }
                  ]
                },
                {
                  path: `${polroczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/zwykly/halfyear/half-year.component').then(m => m.HalfYearComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/zwykly/halfyear/half-year.component').then(m => m.HalfYearComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/zwykly/halfyear/half-year.component').then(m => m.HalfYearComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/zwykly/halfyear/half-year.component').then(m => m.HalfYearComponent) }
                  ]
                },
                {
                  path: `${kominiarski}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) }
                  ]
                },
                {
                  path: `${kominiarskiPolroczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) }
                  ]
                },
                {
                  path: `${gazowy}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) }
                  ]
                },
                {
                  path: `${gazowyPolroczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) }
                  ]
                }
              ]
            },
            {
              path: `${msk}`,
              canActivate: [RoleGuard],
              data: { roleClaims: ['engineer_access'] },
              children: [
                {
                  path: `${roczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/annual/msk-annual-add.component').then(m => m.MskAnnualComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/annual/msk-annual-add.component').then(m => m.MskAnnualComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/annual/msk-annual-add.component').then(m => m.MskAnnualComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/annual/msk-annual-add.component').then(m => m.MskAnnualComponent) }
                  ]
                },
                {
                  path: `${piecioletni}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/quinquennial/msk-quinquennial.component').then(m => m.MskQuinquennialComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/quinquennial/msk-quinquennial.component').then(m => m.MskQuinquennialComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/quinquennial/msk-quinquennial.component').then(m => m.MskQuinquennialComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/quinquennial/msk-quinquennial.component').then(m => m.MskQuinquennialComponent) }
                  ]
                },
                {
                  path: `${polroczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/halfyear/msk-halfyear-add.component').then(m => m.MskHalfYearComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/halfyear/msk-halfyear-add.component').then(m => m.MskHalfYearComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/halfyear/msk-halfyear-add.component').then(m => m.MskHalfYearComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/halfyear/msk-halfyear-add.component').then(m => m.MskHalfYearComponent) }
                  ]
                },
                {
                  path: `${kontrolaDokumentacji}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/documentation-control/documentation-control.component').then(m => m.DocumentationControlComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/muszkieter/documentation-control/documentation-control.component').then(m => m.DocumentationControlComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/documentation-control/documentation-control.component').then(m => m.DocumentationControlComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/muszkieter/documentation-control/documentation-control.component').then(m => m.DocumentationControlComponent) }
                  ]
                },
                {
                  path: `${kominiarski}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) }
                  ]
                },
                {
                  path: `${kominiarskiPolroczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/chimney/chimney.component').then(m => m.ChimneyComponent) }
                  ]
                },
                {
                  path: `${gazowy}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) }
                  ]
                },
                {
                  path: `${gazowyPolroczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: false }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${SHOW}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'], isOnlyShow: true }, loadComponent: () => import('./components/protocols/gas/gas.component').then(m => m.GasComponent) }
                  ]
                }
              ]
            },
            {
              path: `${kamienice}`,
              canActivate: [RoleGuard],
              data: { roleClaims: ['engineer_access'] },
              children: [
                {
                  path: `${roczny}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, loadComponent: () => import('./components/protocols/kamienice/annual/lodz-annual.component').then(m => m.LodzAnnualComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, loadComponent: () => import('./components/protocols/kamienice/annual/lodz-annual.component').then(m => m.LodzAnnualComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'] }, loadComponent: () => import('./components/protocols/kamienice/annual/lodz-annual.component').then(m => m.LodzAnnualComponent) }
                  ]
                },
                {
                  path: `${piecioletni}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, children: [
                    { path: `${ADD}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, loadComponent: () => import('./components/protocols/kamienice/quinquennial/lodz-quinquennial.component').then(m => m.LodzQuinquennialComponent) },
                    { path: `${EDIT}`, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] }, loadComponent: () => import('./components/protocols/kamienice/quinquennial/lodz-quinquennial.component').then(m => m.LodzQuinquennialComponent) },
                    { path: `${COMMENT}`, canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'] }, loadComponent: () => import('./components/protocols/kamienice/quinquennial/lodz-quinquennial.component').then(m => m.LodzQuinquennialComponent) }
                  ]
                }
              ]
            }
          ]
      },
      {
        path: 'raporty',
        canActivate: [RoleGuard],
        data: { roleClaims: ['manager_access'] },
        children: [
          {
            path: 'generuj',
            canActivate: [RoleGuard],
            data: { roleClaims: ['manager_access'] },
            loadComponent: () => import('./components/reports/generate-report/generate-report.component').then(m => m.GenerateReportComponent)
          },
          {
            path: 'podglad/:id',
            canActivate: [RoleGuard],
            data: { roleClaims: ['manager_access'] },
            loadComponent: () => import('./components/reports/report-view/report-view.component').then(m => m.ReportViewComponent)
          }
        ]
      },
      {
        path: 'profil',
        canActivate: [RoleGuard],
        data: { roleClaims: ['engineer_access'] },
        loadComponent: () => import('./components/account/profile-edit/profile-edit.component').then(m => m.ProfileEditComponent)
      },

      // error code page
      { path: 'blad/403', component: ErrorCodePageComponent, data: { code: '403', text: 'Nie masz uprawnień do wyświetlania tej strony' } },
      { path: 'blad/404', component: ErrorCodePageComponent, data: { code: '404', text: 'Nie znaleziono strony' } },
      { path: 'blad/500', component: ErrorCodePageComponent, data: { code: '500', text: 'Błąd serwera. Ewidentnie coś poszło nie tak' } },
      { path: '**', component: ErrorCodePageComponent, data: { code: '404', text: 'Nie znaleziono strony. Wpisałeś błędną ścieżkę' } }
    ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
