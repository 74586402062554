import { UserProfileAdapter } from '../models/user-profile';
import { AuthenticationService } from './authentication.service';

export function appInitializer(authenticationService: AuthenticationService, userProfileAdapter: UserProfileAdapter) {
    return () => new Promise(resolve => {
        let userData = userProfileAdapter.adapt(JSON.parse(localStorage.getItem("PrzegladyBudowlaneUser")));
        if (userData && userData.email && userData.firstName && userData.lastName && userData.jwtToken)
        {
            // if user data is in the localStorage it means that probably user opened new page
            authenticationService.handleUserData(userData, false);
            resolve(userData);
        }
        else
        {
            // attempt to refresh token on app start up to auto authenticate
            authenticationService.refreshToken()
                .subscribe()
                .add(resolve);
        }
    });
}