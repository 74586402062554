import { Injectable } from '@angular/core';
import { ProtocolType } from 'src/app/models/protocol-type';
import { WorkObjectPhoto } from 'src/app/models/work-object-photo';

export const ADD = 'dodaj/:id';
export const EDIT = 'edytuj/:caseId/:id';
export const SHOW = 'wyswietl/:caseId/:id';
export const COMMENT = 'skomentuj/:caseId/:id';

export const roczny: string = ProtocolType[ProtocolType.BudowlanyRoczny];
export const piecioletni: string = ProtocolType[ProtocolType.BudowlanyPiecioletni];
export const kominiarski: string = ProtocolType[ProtocolType.Kominiarski];
export const kominiarskiPolroczny: string = ProtocolType[ProtocolType.KominiarskiPolroczny];
export const polroczny: string = ProtocolType[ProtocolType.Polroczny];
export const gazowy: string = ProtocolType[ProtocolType.Gazowy];
export const gazowyPolroczny: string = ProtocolType[ProtocolType.GazowyPolroczny];
export const kontrolaDokumentacji: string = ProtocolType[ProtocolType.KontrolaDokumentacji];

@Injectable({
    providedIn: 'root',
})
export class ProtocolFunctionsService {

    constructor() { }

    public filterPhotosBasedOnTags(workObjectPhotos: WorkObjectPhoto[], choosenTags: number[]) : WorkObjectPhoto[] {
        var filteredPhotos: WorkObjectPhoto[] = [];
        workObjectPhotos.forEach(wop => {
            let tagNumbersFromWorkObjectPhoto = wop.tags.map(t => t.id);
            for (let choosenTag of choosenTags) {
                if (!tagNumbersFromWorkObjectPhoto.includes(choosenTag))
                    break;

                let isChoosenTagLast = choosenTags.indexOf(choosenTag) == choosenTags.length - 1;
                if (isChoosenTagLast)
                    filteredPhotos.push(wop);
            }
        })
        return filteredPhotos;
    }

    public getDateStringForInputDate(date: Date) {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    }
}