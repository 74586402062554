export enum ProtocolType {
  Nierozpoznany = 0,
  BudowlanyRoczny = 1,
  BudowlanyPiecioletni = 2,
  Kominiarski = 3,
  Polroczny = 4,
  Gazowy = 5,
  KontrolaDokumentacji = 6,
  Budowlany = 7,
  KominiarskiPolroczny = 8,
  GazowyPolroczny = 9
}