import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401].includes(err.status) && this.authenticationService.userValue) {
                //auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload();
            }
    
            if ([403].includes(err.status)) {
                this.router.navigate(['/blad/403']);
            }
    
            if ([404].includes(err.status)) {
                this.router.navigate(['/blad/404']);
            }
    
            if ([500].includes(err.status)) {
                this.router.navigate(['/blad/500']);
            }
    
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
