export class ToastDetails {
    constructor(public title: string,
        public content: string,
        public type: ToastType) { }
}

export enum ToastType {
    success,
    info,
    warning,
    error
}