<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="text-center my-5">
                <h1 class="font-weight-bold text-error">Kod błędu: {{code}} </h1>
                <img src="assets/images/error.jpg" alt="" class="error-img">
                <h3 class="text-uppercase"> {{text}} </h3>
                <div class="mt-5 text-center">
                    <a routerLink="/"><button class="btn btn-primary">Wróć na stronę główną</button></a>
                </div>
            </div>
        </div>
    </div>
</div>