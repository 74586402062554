import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-error-code-page',
    templateUrl: './error-code-page.component.html',
    styleUrls: ['./error-code-page.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class ErrorCodePageComponent implements OnInit, OnDestroy {

  public sub: Subscription;
  public code: number;
  public text: string;

  constructor(private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub = this.route
      .data
      .subscribe(v => 
        {
          this.code = v.code;
          this.text = v.text;
        });
  }

}
