import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { registerLicense } from '@syncfusion/ej2-base';

import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { EditService, ExcelExportService, FilterService, GroupService, PageService, ResizeService, SearchService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { UserProfileAdapter } from './app/models/user-profile';
import { AARouteReuseStrategy } from './app/services/aaroute-reuse-strategy';
import { appInitializer } from './app/services/app.initializer';
import { AuthenticationService } from './app/services/authentication.service';
import { HttpInterceptorService } from './app/services/http-interceptor.service';
import { JwtInterceptor } from './app/services/jwt.interceptor';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ecnRRRmlZUUF/W0M=');

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(CommonModule, BrowserModule, AppRoutingModule, ToastModule, UploaderModule),
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, UserProfileAdapter] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: RouteReuseStrategy, useClass: AARouteReuseStrategy },
    AuthenticationService,
    DatePipe,
    EditService,
    ToolbarService,
    PageService,
    FilterService,
    SearchService,
    SortService,
    GroupService,
    ExcelExportService,
    ResizeService,
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
  .catch(err => console.error(err));
