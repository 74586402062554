import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, timer } from "rxjs";
import { catchError, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

const apiClients = "/api/clients";
export const GetAllClients = `${apiClients}/GetAllClients`;
export const GetClientTypes = `${apiClients}/GetClientTypes`;

const apiMobile = "/api/mobile";
export const GetNewestActiveMobileVersion = `${apiMobile}/GetNewestActiveMobileVersion`;

const apiClientTasks = "/api/clientTasks";
export const GetDeletedClientTasks = `${apiClientTasks}/GetDeletedClientTasks`
export const GetAllClientTasks = `${apiClientTasks}/GetAllClientTasks`;
export const GetManagers = `${apiClientTasks}/GetManagers`
export const GetOwners = `${apiClientTasks}/GetOwners`
export const GetEngineersWithChimneySweeps = `${apiClientTasks}/GetEngineersWithChimneySweeps`

const apiProtocols = "/api/protocols";
export const GetAllEngineerSignatures = `${apiProtocols}/GetAllEngineerSignatures`;
export const GetBuildingSafetyRatings = `${apiProtocols}/GetBuildingSafetyRatings`;
export const GetBookStatusRatings = `${apiProtocols}/GetBookStatusRatings`;
export const GetDocumentationStatusRatings = `${apiProtocols}/GetDocumentationStatusRatings`;
export const GetCurrentWorkflowYearDates =`${apiProtocols}/GetCurrentWorkflowYearDates`;
export const GetPostControlRecommendationPriorities = `${apiProtocols}/GetPostControlRecommendationPriorities/`;
export const GetSuitabilityForExploitationRatings = `${apiProtocols}/GetSuitabilityForExploitationRatings`;
export const GetElementStateRatings =  `${apiProtocols}/GetElementStateRatings/`;
export const GetProtocolTypes = `${apiProtocols}/GetProtocolTypes/`;
export const GetBuildingStateRatings = `${apiProtocols}/GetBuildingStateRatings/`;

const apiWorkObjects = "/api/workObjects";
export const GetAllTags = `${apiWorkObjects}/GetAllTags/`;

const apiAdmin = '/api/admin'
export const GetAllUsers = `${apiAdmin}/GetAllUsers`;

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(private http: HttpClient) { }

  private cache: { [url: string]: any } = {};
  private refreshInterval = 30 * 60 * 1000; // 30 minutes in milliseconds

  public clearCache() {
      this.cache = {};
  }

  public getData(url: string, forceFetch: boolean = false): Observable<any> {
    if(!forceFetch) {
        // Check if the data is already in the cache
        if (this.cache[url]) {
            console.log(`[CACHE] Data retrieved from cache for URL: ${url}`);
            return this.cache[url];
        }

        // Check localStorage if cache is empty
        const cachedData = localStorage.getItem(url);
        if (cachedData) {
            console.log(`[CACHE] Data retrieved from localStorage for URL: ${url}`);
            const parsedData = JSON.parse(cachedData);
            const dataObservable = of(parsedData).pipe(shareReplay(1));
            this.cache[url] = dataObservable; // Save to local memory
            return dataObservable;
        }
    }

    console.log(`[CACHE] Fetching data from API for URL: ${url} (forceFetch: ${forceFetch})`);
    // If forceFetch is true, fetch data from the API and set up refresh timer
    const dataObservable = this.fetchData(url).pipe(
        shareReplay(1), // Ensure the same data is shared among all subscribers
        switchMap(data => {
            console.log(`[CACHE] Initial data fetched for URL: ${url}`, data);
            // Save data to localStorage
            localStorage.setItem(url, JSON.stringify(data));

            // Set up a timer to refresh data after the specified interval
            return timer(this.refreshInterval, this.refreshInterval).pipe(
                switchMap(() => {
                    console.log(`[CACHE] Timer triggered for URL: ${url}, refreshing data`);
                    return this.fetchData(url);
                }),
                tap(refreshedData => {
                    console.log(`[CACHE] Data refreshed for URL: ${url}`, refreshedData);
                    // Update the cache and localStorage with the refreshed data
                    this.cache[url] = of(refreshedData).pipe(shareReplay(1));
                    localStorage.setItem(url, JSON.stringify(refreshedData));
                }),
                startWith(data) // Emit the initial data immediately
            );
        }),
        catchError(error => {
            console.error(`[CACHE] Error fetching data from ${url}:`, error);
            return of(null);
        })
    );

    // Store the data observable in the cache
    this.cache[url] = dataObservable;

    return dataObservable;
  }

  private fetchData(url: string): Observable<any> {
    return this.http.get(url).pipe(
        tap(data => {
            console.log(`Data fetched from API for URL: ${url}`, data);
        })
    );
  }
}