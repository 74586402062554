import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ToastDetails, ToastType } from '../models/toast-details';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private toastSubject: BehaviorSubject<ToastDetails>;

    public get toastObservable() {
        return this.toastSubject.asObservable();
    }

    constructor() {
        this.toastSubject = new BehaviorSubject<ToastDetails>(new ToastDetails('test', 'testowy', ToastType.info));
    }

    public showToast(title: string, content: string, type: ToastType) {
        this.toastSubject.next(new ToastDetails(title, content, type));
    }
}