import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userValue;
        const roleClaims = route.data.roleClaims;

        var userHaveAppropriateRole = false;

        if (Array.isArray(user.userRoles))
        {
            userHaveAppropriateRole = (roleClaims as string[]).some(roleClaim => user.userRoles.includes(roleClaim));
        }
        else {
            userHaveAppropriateRole = roleClaims.includes(user.userRoles);
        }        

        if (user && userHaveAppropriateRole) {
            return true;
        } else {
            this.router.navigate(['/blad/403']);
            return false;
        }
    }
}
