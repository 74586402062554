import { Injectable } from "@angular/core";
import {HubConnection, HubConnectionBuilder, HttpTransportType} from '@microsoft/signalr';

@Injectable({providedIn: 'root'})
export class SignalrService {
    constructor() {}

    hubConnection:HubConnection;

    startConnection = (route: string) => {
        this.hubConnection = new HubConnectionBuilder()
        .withUrl(`/${route}`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        })
        .build();

        this.hubConnection
        .start()
        .catch(err => console.log("Error while starting connection: " + err))
    }
}